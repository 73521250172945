import "./Header.css"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { Modal, Button } from "antd"
import m2 from "../../src/images/m2.png"
import pdfdemo from "../../src/images/pdfdemo.png"

function Header(props) {
  // eslint-disable-next-line react/prop-types
  const {
    // eslint-disable-next-line react/prop-types
    onChangeLanguage,
    onStartRecording,
    onStopRecording,
    isStopRecording,
    onHelpModal,
    onHelpHideModal,
    minutesModal,
    onMinutesHelpModal,
    onMinutesHelpHideModal,
    meeting_data,
    showModalSubmit,
    activeEle,
    closeModal,
    onHelpHideModalNextProject,
    onHelpHideModalMinutes,
    onHelpHideModalAttendees,
    onHelpHideModalApologies,
  } = props
  const [startRecordingBtn, setStartRecordingBtn] = useState(false)
  const [stopRecordingBtn, setStopRecordingBtn] = useState(true)
  useEffect(() => {
    if (isStopRecording === false) {
      setStopRecordingBtn(true)
      setStartRecordingBtn(false)
    }
  }, [isStopRecording])
  const options = [
    { value: "ar-EG", label: "Arabic - EG" },
    { value: "ca-ES", label: "Catalan - ES" },
    { value: "zh-CN", label: "Chinese - CN" },
    { value: "zh-HK", label: "Chinese - HK" },
    { value: "zh-TW", label: "Chinese - TW" },
    { value: "da-DK", label: "Danish - DK" },
    { value: "nl-NL", label: "Dutch - NL" },
    { value: "en-AU", label: "English - AU" },
    { value: "en-CA", label: "English - CA" },
    { value: "en-GB", label: "English - GB" },
    { value: "en-IE", label: "English - IE" },
    { value: "en-IN", label: "English - IN" },
    { value: "en-NZ", label: "English - NZ" },
    { value: "en-US", label: "English - US" },
    { value: "de-DE", label: "German - DE" },
    { value: "es-ES", label: "Spanish - ES" },
    { value: "es-MX", label: "Spanish - MX" },
    { value: "fi-FI", label: "Finnish - FI" },
    { value: "fr-CA", label: "French - CA" },
    { value: "fr-FR", label: "French - FR" },
    { value: "hi-IN", label: "Hindi - IN" },
    { value: "it-IT", label: "Italian - IT" },
    { value: "ja-JP", label: "Japanese - JP" },
    { value: "ko-KR", label: "Korean - KR" },
    { value: "nb-NO", label: "Norwegian - NO" },
    { value: "pl-PL", label: "Polish - PL" },
    { value: "pt-BR", label: "Portuguese - BR" },
    { value: "pt-PT", label: "Portuguese - PT" },
    { value: "ru-RU", label: "Russian - RU" },
    { value: "sv-SE", label: "Swedish - SE" },
  ]
  const onChange = (val) => {
    // alert(val.target.value)
    onChangeLanguage(val.target.value)
  }
  const onRecordStart = () => {
    setStartRecordingBtn(true)
    setStopRecordingBtn(false)
    onStartRecording()
  }
  const onRecordStop = () => {
    if (isStopRecording === false) {
      setStopRecordingBtn(true)
      setStartRecordingBtn(false)
    }
    // setStopRecordingBtn(true);
    // setStartRecordingBtn(false);

    onStopRecording()
  }

  return (
    <div data-html2canvas-ignore="true" className="container mt-3 mb-3">
      <div className="row p-2 border1 rounded shadow">
        <div className="col-lg-12 col-md-4 col-7">
          {/* <h3>{meeting_data.meeting_title}</h3> */}
          <h3>
            {meeting_data.meeting_title?.charAt(0)?.toUpperCase() +
              meeting_data.meeting_title?.slice(1)}
            {/* {`start disabled - ${startRecordingBtn} stop disabled - ${stopRecordingBtn}`} */}
          </h3>
          <label data-html2canvas-ignore="true">Recognition Language:</label>
        </div>
        <div
          className="col-lg-4 col-md-4 col-4 py-2"
          data-html2canvas-ignore="true"
        >
          <select
            id="languageOptions"
            className="form-control"
            defaultValue={"en-IN"}
            onChange={(val) => onChange(val)}
          >
            {options.map((c) => (
              <option key={c.value} value={c.value}>
                {c.label}
              </option>
            ))}
          </select>
        </div>
        <div
          className="col-lg-8 p-lg-2 col-md-7 col-7"
          data-html2canvas-ignore="true"
        >
          <button
            className="btn btn-success mx-1 px-1 px-lg-4 px-md-3"
            disabled={startRecordingBtn}
            onClick={(val) => {
              onRecordStart()
            }}
          >
            {activeEle ? "Resume" : "Start"}
          </button>

          <button
            className="btn btn-danger mx-1 px-1 px-lg-4 px-md-3"
            disabled={stopRecordingBtn}
            onClick={(val) => onRecordStop()}
          >
            Stop
          </button>
          <button
            className="btn btn-info mx-1 px-1 px-lg-4 px-md-3"
            onClick={() => {
              // window.location.href = "https://swiftminute-backend.vnvserver.com/organization/home";
              window.location.href = `https://swiftminute-backend.vnnovateserver.com/org-meeting/view/${meeting_data.id}`
            }}
          >
            Template Builder
          </button>
          {/* <button
            className="btn btn-info mx-1 px-1 px-lg-4 px-md-3"
            onClick={(val) => onHelpModal()}
          >
            Help?
          </button> */}
          <Modal
            title="Help?"
            // visible={helpModal}
            onOk={onHelpModal}
            onCancel={onHelpHideModal}
            width={1000}
            centered
            footer={[
              <Button key="back" onClick={onHelpHideModal}>
                Close
              </Button>,
            ]}
          >
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label className=" text-info">
                    <b> How to Start ?</b>
                  </label>
                  <br />
                  <label>
                    - Click on
                    <span className="bg-success text-white">
                      &nbsp; start &nbsp;
                    </span>
                    button to begin speech recognition
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label className=" text-info">
                    <b> How to stop speech recognition ?</b>
                  </label>
                  <br />
                  <label>
                    - say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "stop recording"</b> to stop speech <b> OR</b> click on
                    <span className="bg-danger text-white">
                      &nbsp; Stop &nbsp;
                    </span>
                    to stop speech recognition
                  </label>
                </div>
              </div>

              <div className="col-lg-6">
                <hr />
                <div className="form-group">
                  <label className=" text-info">
                    <b> How it works ?</b>
                  </label>
                  <br />
                  <label>
                    - To trigger event start with saying heading name <br /> Ex.
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "Add Project / Go to date / Go to minutes" </b>
                    <br />
                    <hr />-<b> Project, Next Meeting </b> in this section text
                    focus will auto shift after every voice text
                  </label>
                  <br />
                  <hr />
                  <label className=" text-info">
                    <b> How to easy navigate without adding word ?</b>
                  </label>
                  <br />
                  <label>
                    - This work in
                    <span className="text text-success">
                      "Project" , "Next Meeting" and "Minutes"
                    </span>
                    section <br />- To move previous text box say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "Go back / Previous"</b> <br />- To move next text box
                    say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "Next"</b>
                    <br />
                    <hr />- For <b> Minutes</b> to navigate say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "Sr number"</b> <br />
                    Ex.
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "1.1" </b>
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <hr />
                <div className="form-group">
                  <label className=" text-info">
                    <b> How to pause speech recognition ?</b>
                  </label>
                  <br />
                  <label>
                    - say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "pause recording"</b> to pause speech recognition
                  </label>
                  <br />
                  <hr />
                  <label className=" text-info">
                    <b> Commands : </b>
                  </label>
                  <br />
                  <label>
                    - This work in
                    <span className="text text-success">
                      "Project" , "Next Meeting" and "Minutes"
                    </span>
                    section <br />- To clear input field say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "Clear / Delete"</b>
                    <br />- To clear all input fields say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "Reset / Reset form"</b> <br />- To remove recently
                    added sentence say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "Remove / Undo"</b>
                    <br />
                    <hr />- In <b> Minutes</b> to navigate say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "Sr number"</b> <br />
                    Ex.
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "1.1" </b>
                    <br />- To navigate to owner or date fields say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "Owner or Date with sr. number"</b> <br />
                    Ex.
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "Owner 1.1 or Date 1.1" </b>
                    <br />- To navigate to new line in minute fields say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "Next line / Add new line"</b> <br />
                  </label>
                </div>
              </div>

              <div className="col-lg-6">
                <hr />
                <div className="form-group">
                  <label className=" text-info">
                    <b> How to shift attendees record to apologies ?</b>
                  </label>
                  <br />
                  <label>
                    - If Attendees section is not focused then say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "attendees"</b>. after that, by calling serial number of
                    desired record you can shift it to apologies
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <hr />
                <div className="form-group">
                  <label className=" text-info">
                    <b> How to shift apologies record to attendees ?</b>
                  </label>
                  <br />
                  <label>
                    - If apologies section is not focused then say
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mic"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                      <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                    </svg>
                    <b> "apologies"</b>. after that, by calling serial number of
                    desired record you can shift it to attendees
                  </label>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            title="Minutes Table Help"
            visible={minutesModal}
            onOk={onMinutesHelpModal}
            onCancel={onMinutesHelpHideModal}
            width={1000}
            centered
            footer={[
              <Button key="back" onClick={onMinutesHelpHideModal}>
                Close
              </Button>,
            ]}
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group minute-modal">
                  <label className=" text-info">
                    <b> Minute section</b>
                  </label>
                  <br />
                  <p>
                    - You can Focus on minute section by saying{" "}
                    <b> Minutes / Go to minutes</b> <br />
                    - After this, you can focus on any row data
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; Ex. If you want to visit 1st rows
                    minute section then say <b> 1.1</b>
                    <br />- Once focus appears inside table, you can navigate
                    through next and previous text by using{" "}
                    <b> Next / go back</b> <br />- Here is the example how to
                    navigate through minute table
                  </p>
                  <p>
                    - You can add bullet points and number points in minute
                    column <br />- to start bullets points say{" "}
                    <b> Start point</b> & to stop it say <b> Stop point</b>{" "}
                    <br />- to start numbered points say <b> Start sequence</b>{" "}
                    & to stop it say <b> Stop sequence</b> <br />- you can nest
                    bullets inside number point or opposite but you need remind
                    sequence you started Ex.
                  </p>
                  <img src={m2} width="100%" alt="m2" />
                  <hr />

                  <p>- here is how result will shown in pdf</p>
                  <img src={pdfdemo} width="60%" alt="pdfdemo" />
                  <hr />
                  <p>
                    - To remove last sentence use: <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <b> Remove / Undo</b>
                  </p>
                  <hr />
                  <p>
                    - To add next line use: <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <b>
                      add new line / next line / add paragraph / new paragraph
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </Modal>
          {/* <button
            className="btn btn-info mx-1 px-1 px-lg-4 px-md-3"
            onClick={(val) => onMinutesHelpModal()}
          >
            Minutes Table?
          </button> */}
          <button
            className="btn btn-primary mx-1 px-1 px-lg-4 px-md-3"
            onClick={(val) => {
              showModalSubmit()
              closeModal()
              onHelpHideModalAttendees()
              onHelpHideModalApologies()
              onHelpHideModalNextProject()
              onHelpHideModalMinutes()
              setStopRecordingBtn(false)
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header
